<template>
  <div>
    <!-- <div class="row items-center q-mt-md q-mb-xs">
      <q-btn-group class="q-ml-sm">
        <q-btn
          size="sm"
          :text-color="isInternational ? 'dark' : 'white'"
          :color="isInternational ? 'grey-3' : 'light-blue-9'"
          :icon="isInternational ? undefined : 'done'"
          :disable="false"
          :label="$t('Domestic')"
          @click="isInternational = false"
        />

        <q-btn
          size="sm"
          :text-color="isInternational ? 'white' : 'dark'"
          :color="isInternational ? 'light-blue-9' : 'grey-3'"
          :icon="isInternational ? 'done' : undefined"
          :disable="false"
          :label="$t('International')"
          @click="isInternational = true"
        />
      </q-btn-group>
    </div> -->

    <div v-if="error" class="text-subtitle1 text-center q-pa-sm text-negative">
      {{ error }}
    </div>

    <div v-else>
      <c-address
        :is-loading="!address"
        :type="addressType"
        :model="address || {}"
        :disabled="isClient"
        @address="updateCurrentAddress"
      >
        <base-address
          :is-loading="!address"
          :disabled="isClient"
          :address="address || undefined"
          @change="updateCurrentAddress"
        />
      </c-address>

      <div class="text-center q-pa-sm">
        <q-btn
          color="light-blue-9"
          text-color="white"
          :label="$t('Save')"
          :disable="!isUpdated || isLoading"
          @click="save"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Vue
import { defineAsyncComponent } from 'vue'

// Components
import BaseAddress from '../../components/clients/BaseAddress.vue'

export default {
  name: 'WarehouseSenderPanel',
  components: {
    CAddress: defineAsyncComponent(() => import('../clients/Address.vue')),
    BaseAddress,
  },
  props: {
    owner: {
      type: [String, Number],
      default () {
        return null
      }
    },
    id: {
      type: [String, Number],
      default () {
        return null
      }
    }
  },
  data () {
    return {
      error: '',
      isLoading: false,
      isUpdated: false,
      isInternational: true,
      simple: false
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
      'address',
      'isClient'
    ]),
    addressType () {
      let rightTab = this.simple ? 'domestic' : 'international'
      let leftTab = this.simple ? 'international' : 'domestic'

      return this.isInternational
          ? rightTab
          : leftTab
    },
  },
  mounted () {
    if (this.id) {
      this.isLoading = true

      this.$service.profile.get(this.id)
        .then(({ address }) => {
          this.isLoading = false
          this.setAddress(address)
        })
    } else if (this.owner) {
      this.isLoading = true
      const query = {
        per_page: 5,
        page: 1,
        filter: [
          { type: 'eq', field: 'owner', value: this.owner }
        ]
      }

      this.$service.sender.getAll(query)
        .then(({ items, totalItems }) => {
          this.isLoading = false
          if (totalItems <= 0) {
            this.error = this.$t('Sorry, but owner of warehouse don\'t have senders!')
            return
          }

          if (totalItems > 1) {
            this.error = this.$t('Sorry, we found that owner of warehouse has more than one senders! Please setup default sender of warehouse.')
            return
          }

          this.$service.profile.get(items[0].id, items[0])
            .then(({ address }) => {
              this.isLoading = false
              this.setAddress(address)
            })
        })
    } else {
      this.error = this.$t('Sorry but something is wrong with warehouse. Warehouse don\'t have owner and default sender.')
    }

    this.simple = this.appOptions.versionType === 'simple'
  },
  unmounted () {
    this.setAddress(null)
  },
  methods: {
    ...mapMutations([
      'updateAddress',
      'setAddress'
    ]),
    ...mapActions([
      'saveAddress'
    ]),
    updateCurrentAddress (address) {
      this.updateAddress(address)
      this.isUpdated = true
    },
    save () {
      this.isLoading = true
      return this.saveAddress()
        .then(() => {
          this.isUpdated = false
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
